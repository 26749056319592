import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createResource, createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const freightOrders = {
  list: r('/freight-orders/list/{?s,filters,order_by,limit,offset}'),
  exportList: createResource(prefixAPI('/freight-orders/list/xls/export/{?filters,order_by}')),
  simpleList: r('/freight-orders/simple/list/{?s,filters,order_by,limit,offset}'),
  aggregate: r('/freight-orders/aggregate/{?filters,order_by,limit,offset}'),
  addons: {
    statuses: r('/freight-orders/addons/statuses/'),
  },
  filters: {
    list: r('/freight-orders/filters/list/'),
    available: r('/freight-orders/filters/list/{?filters}'),
  },
  create: r('/freight-orders/create/', createSender),
  retrieve: r('/freight-orders{/id}/retrieve/'),
  update: r('/freight-orders{/id}/update/', createSender).config('method', 'PATCH'),
  pdf: r('/freight-orders{/id}/pdf/'),
};

export const parcels = {
  list: r('/parcels/list/{?filters,order_by,limit,offset}'),
  exportList: createResource(prefixAPI('/parcels/list/xls/export/{?filters,order_by}')),
  aggregate: r('/parcels/aggregate/{?filters,order_by,limit,offset}'),
  addons: {
    statuses: r('/parcels/addons/statuses/'),
  },
  plural: {
    changeClientRate: r('/parcels/plural/change/client-rate/', createSender),
    changeStatus: r('/parcels/plural/change/status/', createSender),
    changeTransferMethod: r('/parcels/plural/change/transfer-method/', createSender),
    changeShipment: r('/parcels/plural/change/shipment/', createSender),
  },
  filters: {
    list: r('/parcels/filters/list/'),
    available: r('/parcels/filters/list/{?filters}'),
  },
  create: r('/parcels/create/', createSender),
  delete: r('/parcels/{id}/delete/', createSender).config('method', 'POST'),
  dispatch: r('/parcels/dispatch/', createSender),
  retrieve: r('/parcels{/id}/retrieve/'),
  update: r('/parcels{/id}/update/', createSender).config('method', 'PATCH'),
  receive: r('/parcels/receive/', createSender),
};

export const shipments = {
  list: r('/shipments/list/{?filters,order_by,limit,offset}'),
  simpleList: r('/shipments/simple/list/{?filters,order_by,limit,offset}'),
  aggregate: r('/shipments/aggregate/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/shipments/filters/list/'),
    available: r('/shipments/filters/list/{?filters}'),
  },
  getNumber: r('/shipments/get-number/', createSender),
  regenerate: r('/shipments/regenerate/', createSender),
  delete: r('/shipments/{id}/delete/', createSender).config('method', 'POST'),
  create: r('/shipments/create/', createSender),
  retrieve: r('/shipments{/id}/retrieve/'),
  tracking: r('/shipments{/id}/tracking/'),
  excel: r('/shipments{/id}/excel/'),
  pdf: r('/shipments{/id}/pdf/'),
  update: r('/shipments{/id}/update/', createSender).config('method', 'PATCH'),
};

// export const shipmentParcels = {
//   list: r('/shipment-parcels/list/{?filters,order_by,limit,offset}'),
//   create: r('/shipment-parcels/create/', createSender),
//   listCreate: r('/shipment-parcels/list-create/', createSender),
//   changeShipment: r('/shipment-parcels/change-shipment/', createSender),
//   departureCheck: r('/shipment-parcels/departure-check/', createSender),
//   destinationCheck: r('/shipment-parcels/destination-check/', createSender),
// };

export const locationsApi = {
  list: r('/locations/departure/list/'),
};

export const unidentifiedApi = {
  list: r('/unidentified/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/unidentified/filters/list/'),
    available: r('/unidentified/filters/list/{?filters}'),
  },
  create: r('/unidentified/create/', createSender),
  retrieve: r('/unidentified{/id}/retrieve/'),
  update: r('/unidentified{/id}/update/', createSender).config('method', 'PATCH'),
};
export const unidentifiedSearchApi = {
  list: r('/unidentified-search/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/unidentified-search/filters/list/'),
    available: r('/unidentified-search/filters/list/{?filters}'),
  },
  create: r('/unidentified-search/create/', createSender),
  retrieve: r('/unidentified-search{/id}/retrieve/'),
  update: r('/unidentified-search{/id}/update/', createSender).config('method', 'PATCH'),
};
